export default {
    name: 'AppFooter',    
    created() { 

        
    },
    data() {
        return {            
        };
    },
  
    methods: {

        href(url) {
            this.$router.push(url);
        },
      
    }    
}
